import React from "react";
import Message from "../components/contact/message";
import Email from "../components/contact/email";
import Social from "../components/contact/social";
import styles from "../styles/contact.module.scss";

export default function Index() {
  return (
    <div>
      <h1 className={ `header`}>
        Contact
      </h1>
      <p className={ styles.header__text }>
        Please feel free to reach out and send me a message!
      </p>

      <div className={ styles.container }>
        <div className={ styles.left } >
          <Message />
        </div>
        <div className={ styles.right }>
          <Email />
          <Social />
        </div>
      </div>
    </div>
  )
};
